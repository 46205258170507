<template>
<div>
    <div class="loading-gif" v-if="!fadeIn">
      <img src="../assets/img/loading.gif">
    </div>

  <div class="portfolioItem container" :class="{ fadeIn: fadeIn }">

      <div class="portfolioItem-item" v-for="(item, index) in items" :key="index">
        <img class="portfolioItem-item-img" :src="'https://julie.pash.rocks' + item.image" v-on:load="onLoaded(item.image)">
        <inner-image-zoom :src="'https://julie.pash.rocks' + item.image" :zoomSrc="'https://julie.pash.rocks' + item.image" />

      </div>

  </div>
</div>
</template>

<script>
  import InnerImageZoom from 'vue-inner-image-zoom';
  import axios from 'axios'
  export default {
    components: {
      'inner-image-zoom': InnerImageZoom
    },
    data: function () {
      return {
        items: [
        ],
        currentZoom: false,
        fadeIn: false,
        totalItems: 0
      }
    },
    methods: {
      loadItems: function () {
        var app = this
        var id = app.$route.params.id
        axios.get('https://julie.pash.rocks/api/portolio/item/' + id).then(function(response) {
          var listaItems = response.data
          app.items = listaItems
        })
      },
      onLoaded() {
        var itemCount = this.items.length - 1
        if (this.totalItems === itemCount) {
          this.fadeIn = true;
          console.log("fadeIn")
        } else {
          this.totalItems++;
          console.log(itemCount + " " + this.totalItems)
        }
      }
    },
    mounted () {
      this.loadItems()
    }

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.iiz__btn.iiz__hint {
  opacity: 0;
}

.portfolioItem {
  padding-top: 70px;
  padding-top: 60px;
  opacity: 0;
  transition: opacity 1.5s;

  &.fadeIn {
    opacity: 1;
    height: auto;
  }
  &-item {
    width: 100%;
    margin-bottom: 20px;
    &-img {
      display: none;
    }
    img {
      cursor: zoom-in;
    }

  }
}

@media only screen and (max-width: 800px) {
  .portfolioItem  {
    &.container {
      padding: 0;
      padding-top: 70px;
      }
    &-item-zoom {
      display: none !important;
    }

  }
}

</style>
